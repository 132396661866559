<template>
  <v-container class="full-height">
    <v-row class="full-height" align="center">
      <v-col cols="12">
        <v-card
          class="mx-auto box-shadow rounded-lg" 
          max-width="500" 
          outlined
        >
          <v-alert
            v-if="submitted"
            type="success"
            colored-border
            border="left"
            class="mb-0"
          >
            Your details has been submitted for approval. Please wait for a confirmation email.
          </v-alert>

          <template v-else>
            <v-card-title class="justify-center secondary white--text">
              <v-icon left dark>mdi-account-circle-outline</v-icon>
              <span>Dietitian Registration</span>
            </v-card-title>
            
            <v-divider></v-divider>
            
            <v-card-text class="pa-5">
              <v-alert v-if="status.error" type="error" border="left" class="mb-7">
                {{ status.error }}
              </v-alert>

              <v-form ref="signupForm" @submit.prevent="validateForm()">
                <v-text-field
                  :rules="[rules.required]"
                  v-model="data.firstName"
                  label="First Name"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  :rules="[rules.required]"
                  v-model="data.lastName"
                  label="Last Name"
                  outlined
                  dense
                ></v-text-field>

                <v-radio-group 
                  :rules="[rules.required]"
                  v-model="data.gender" 
                  class="mt-0 pt-0" 
                  dense
                  row 
                >
                  <v-radio
                    label="Male"
                    value="male"
                  ></v-radio>
                  <v-radio
                    label="Female"
                    value="female"
                  ></v-radio>
                </v-radio-group>

                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="290px"
                  v-model="menu"
                  ref="menu"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :rules="[rules.required]"
                      v-model="data.birthDate"
                      label="Birth Date"
                      v-on="on"
                      readonly
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :max="new Date().toISOString().substr(0, 10)"
                    v-model="data.birthDate"
                    min="1950-01-01"
                    ref="picker"
                  ></v-date-picker>
                </v-menu>

                <v-text-field
                  :rules="[rules.required, rules.email]"
                  label="Email Address"
                  v-model="data.email"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  :rules="[rules.required]"
                  v-model="data.phone"
                  label="Phone Number"
                  outlined
                  dense
                ></v-text-field>
                
                <v-text-field
                  label="APD / Dietitian Registration Number"
                  :rules="[rules.required]"
                  v-model="data.apd"
                  outlined
                  dense
                ></v-text-field>

                <v-radio-group 
                  v-model="data.energyUnit" 
                  :rules="[rules.required]"
                  label="Energy unit:" 
                  class="mt-0"
                  row
                >
                  <v-radio label="Kilojoule" value="kj" dense></v-radio>
                  <v-radio label="Calorie" value="cal" dense></v-radio>
                </v-radio-group>
                
                <v-text-field
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[rules.required, rules.password]"
                  v-model="data.password"
                  label="Password"
                  outlined
                  dense
                ></v-text-field>

                <div class="d-flex align-start mb-5">
                  <v-checkbox 
                    v-model="data.agreeTermsAndConditions"
                    :rules="[rules.required]"
                    class="mt-0"
                    hide-details
                    dense
                  ></v-checkbox>

                  <div>By ticking, you are confirming that you have read, understood and agree to our <a href="#" @click.prevent="$refs.termsDialog.showDialog()">Terms and Conditions</a>.</div>
                </div>

                <v-btn
                  @click="validateForm()"
                  color="primary white--text"
                  :loading="status.adding"
                  depressed
                  block
                  large
                >Submit</v-btn>

                <div class="mt-3 text-center">
                  Already a member? <router-link :to="{ name: 'Login' }">Login here</router-link>.
                </div>

              </v-form>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <TermsDialog ref="termsDialog" />
  </v-container>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

import TermsDialog from './components/TermsDialog.vue'

export default {
  metaInfo: {
    title: 'Dietitian Registration'
  },

  components: { TermsDialog },

  data() {
    return {
      rules,
      menu: null,
      submitted: false,
      showPassword: false,
    }
  },

  computed: {
    ...mapState({
      data: state => state.register.data,
      status: state => state.register.status,
    })
  },

  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },

  methods: {
    ...mapActions('register', [
      'registerUser',
    ]),

    validateForm() {
      if (this.$refs.signupForm.validate()) {
        this.$store.commit('register/setDataFieldValue', {
          field: 'status',
          value: 'pending'
        })
        
        this.$store.commit('register/setDataFieldValue', {
          field: 'role',
          value: 'dietitian'
        })

        Promise.resolve(this.registerUser())
        .then(() => {
          this.submitted = true
        },
        (error) => {
          this.$store.dispatch('showError', error)
          this.$store.commit('register/updateStatus', { creating: false })
        })
      }
    }
  },

  mounted() {
    if (this.$route.query.ref) {
      this.$store.commit('register/setDataFieldValue', {
        field: 'referee', 
        value: this.$route.query.ref
      })
    }
  }
}

</script>
